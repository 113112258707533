import React, { Component } from "react";
import { StyleSheet, View, Text } from "react-native";
import { number, bool } from "prop-types";
import { colors } from "../../lib/stylesheet";

const propTypes = {
    time: number.isRequired,
    etd: bool,
    sizeMultiplier: number
};

class TimeReference extends Component {
    constructor(props) {
        super(props);
        this.state = {
            time: this.props.time
        };
    }

    componentWillReceiveProps(props) {
        const { time } = this.props;
        if (props.time !== time) {
            this.setState({ time: props.time });
        }
    }

    render() {
        const styles = StyleSheet.create({
            eta: {
                fontSize: this.props.sizeMultiplier
                    ? 12 * this.props.sizeMultiplier
                    : 12,
                fontWeight: "100",
                color: colors.textSecondaryAccent
            },
            time: {
                fontSize: this.props.sizeMultiplier
                    ? 22 * this.props.sizeMultiplier
                    : 22,
                fontWeight: "bold",
                color: colors.textPrimary
            },
            min: {
                fontSize: this.props.sizeMultiplier
                    ? 16 * this.props.sizeMultiplier
                    : 16,
                color: colors.textAccent
            }
        });
        return (
            <View style={{ alignItems: "center" }}>
                <Text style={[styles.eta]}>ETA</Text>
                <Text style={[styles.time]}>{this.state.time}</Text>
                <Text style={[styles.min]}>MIN</Text>
            </View>
        );
    }
}

TimeReference.propTypes = propTypes;

export default TimeReference;
