import store from "./store";
import fetch from "isomorphic-unfetch";

export { fetchVehicles, fetchSingleVehicle, fetchSubrouteStops };

const serverUrl = process.env.REACT_APP_SERVER_URL;
/**
 * Fetches single vehicle ETAs and meta data
 * @param {string} vehicleId
 * @returns {Promise<*>}
 */
async function fetchSingleVehicle(vehicleId) {
    const currentLocation = store.getState().geo.coords;
    const { latitude, longitude } = currentLocation;
    try {
        const res = await fetch(
            `${serverUrl}/rest/route/etaByVehicle/${vehicleId}?lat=${latitude}&lng=${longitude}`,
            {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            }
        );
        let json = await res.json();
        if (json.length === 0) {
            const longitude = -66.072121;
            const latitude = 18.449107;
            const res = await fetch(
                `${serverUrl}/rest/route/etaByVehicle/${vehicleId}?lat=${latitude}&lng=${longitude}`,
                {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json"
                    }
                }
            );
            json = await res.json();
            return json;
        }
        return json;
    } catch (err) {
        console.error("ERROR _fetchVehicles: ", err);
        return err;
    }
}

async function fetchVehicles() {
    const currentLocation = store.getState().geo.coords;
    const { latitude, longitude } = currentLocation;
    try {
        const res = await fetch(
            `${serverUrl}/rest/route/nearbyEtas?lat=${latitude}&lng=${longitude}`,
            {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            }
        );
        let json = await res.json();
        if (json.length === 0) {
            await store.dispatch({
                type: "SET_GEO",
                payload: {
                    geo: {
                        coords: {
                            latitude: 18.449107,
                            longitude: -66.072121
                        }
                    }
                }
            });
            const latitude = store.getState().geo.coords.latitude;
            const longitude = store.getState().geo.coords.longitude;
            const res = await fetch(
                `${serverUrl}/rest/route/nearbyEtas?lat=${latitude}&lng=${longitude}`,
                {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json"
                    }
                }
            );
            json = await res.json();
            return json;
        }
        return json;
    } catch (err) {
        console.error("ERROR _fetchVehicles: ", err);
        return err;
    }
}

async function fetchSubrouteStops(subRouteId) {
    try {
        const res = await fetch(
            `${serverUrl}/rest/stop/subroute/${subRouteId}`,
            {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            }
        );
        const json = await res.json();
        const mappedStops = json.features.map(stop => ({
            ...stop,
            stopName: stop.properties.name
        }));
        return mappedStops;
    } catch (err) {
        console.error("ERROR fetchSubRouteStops: ", err);
        return err;
    }
}
