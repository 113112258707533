import React from "react";
import { array, oneOfType, node, object, number, string } from "prop-types";
import { StyleSheet, Text } from "react-native";
import { colors } from "../lib/stylesheet";

const propTypes = {
    children: node.isRequired,
    style: oneOfType([object, array]),
    size: number,
    weight: string,
    color: string
};

function AppText(props) {
    const styles = StyleSheet.create({
        text: {
            fontFamily: "Helvetica neue",
            fontSize: props.size,
            fontWeight: props.weight,
            color: props.color || colors.textPrimary,
            letterSpacing: 4
        }
    });
    return <Text style={[styles.text, props.style]}>{props.children}</Text>;
}

AppText.propTypes = propTypes;

export default AppText;
