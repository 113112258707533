import React, {Component, Fragment} from 'react';
import {Marker} from 'react-mapbox-gl';


export default class StopsLayer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            stopsGeoJson: {
                type: 'FeatureCollection',
                features: [],
            }
        }
    }

    async componentDidMount() {
        let url = `${process.env.REACT_APP_SERVER_URL}/rest/stop/byRouteCodes/${process.env.REACT_APP_ROUTE_CODES}`;
        const response = await fetch(url);
        const stopsGeoJson = await response.json();
        this.setState({
            stopsGeoJson: stopsGeoJson
        })
    }



    render() {
        return (
            <Fragment>
                {this.state.stopsGeoJson && this.state.stopsGeoJson.features.map((m, i) => (
                    <Marker coordinates={m.geometry.coordinates} key={i}>
                        <div className="station">
                            <span>{m.properties.name}</span>
                        </div>
                    </Marker>
                ))}
            </Fragment>
        )
    }
}

