import React from "react";
import {StyleSheet, View} from "react-native";
import _ from "lodash";
import Header from "../../components/Header";
import Vehicles from "../../components/Vehicles";
import {vehicle_route_map} from "../../utils";
import {fetchVehicles} from "../../service";
import {colors} from "../../lib/stylesheet";
import QRCode from "./QRCode";
import FloatingClimate from "./FloatingClimate";
import Map from "./Map";
import store from "../../store";

export default class Home extends React.Component {
    state = {
        vehicles: [],
        loading: true
    };
    componentDidMount() {
        const coords = process.env.REACT_APP_ETA_LOCATION.split(',')
        this._fetchVehicles({
            coords: {
                longitude: coords[0],
                latitude: coords[1],
            }
        });
        /*navigator.geolocation.getCurrentPosition(
            g => this._fetchVehicles(g),
            () =>
                this._fetchVehicles({
                    coords: {
                        longitude: -66.05945963681,
                        latitude: 18.4378473661191
                    }
                })
        );*/
    }

    _fetchVehicles = async geo => {
        try {
            await store.dispatch({
                type: "SET_GEO",
                payload: {
                    geo: {
                        coords: {
                            latitude: geo.coords.latitude,
                            longitude: geo.coords.longitude
                        }
                    }
                }
            });
            const json = await fetchVehicles();
            await this.setState({
                vehicles: _.slice(json.map(vehicle_route_map), 0, 5),
                loading: false
            });
        } catch (err) {
            console.error("ERROR _fetchVehicles: ", err);
            return err;
        }
    };
    render() {
        const styles = StyleSheet.create({
            bottomRightColumn: {
                flex: 1,
                backgroundColor: "white",
                alignItems: "center",
                justifyContent: "center"
            },
            bottomLeftColumn: {
                flex: 6
            },
            rightColumn: {
                flex: 1,
                backgroundColor: colors.primaryBackground
            },
            bottomColumns: {
                flexDirection: "row",
                height: "23%",
                backgroundColor: colors.secondaryAccentBackground
            },
            middleColumn: {
                flex: 4
            },
            map: {
                width: "100%",
                height: "100%"
            },
            leftColumn: {
                flex: 2
            },
            columnContainer: {
                flexDirection: "row",
                justifyContent: "space-between",
                height: `${window.innerHeight - 40}px`
            },
            container: {
                // height: "100vh"
            }
        });
        return (
            <View style={[styles.container]}>
                <View style={[styles.columnContainer]}>
                    <View style={[styles.leftColumn]}>
                        <Header />
                        <Vehicles
                            loading={this.state.loading}
                            vehicles={this.state.vehicles}
                        />
                        <QRCode />
                    </View>
                    <View style={[styles.middleColumn]}>
                        <Map />
                        <FloatingClimate />
                        {/*<FloatingInfo />*/}
                    </View>
                </View>
            </View>
        );
    }
}
