import React from "react";
import { StyleSheet, View } from "react-native";
import { string, object } from "prop-types";
import AppText from "../AppText";
import TripDisplacementBar from "./TripDisplacementBar";
import TripDisplacementBar2 from "./TripDisplacementBar2";
import fetch from "isomorphic-unfetch";
import { colors, spacings } from "../../lib/stylesheet";
import {fetchSubrouteStops} from "../../service";

const propTypes = {
    name: string.isRequired,
    goingTo: string.isRequired,
    vehicle: object.isRequired
};

class InfoAndStops extends React.Component {
    state = {
        subRouteStops: [],
        error: false
    };
    // componentDidMount() {
    //     this._fetchSubRouteStops(this.props.vehicle.subRouteId);
    // }
    componentDidUpdate(prevProps) {
        if (prevProps.vehicle !== this.props.vehicle) {
            this._fetchSubRouteStops(this.props.vehicle.subRouteId);
        }
    }
    /**
     * Fetches the sub route stops and set the state
     * @param {string} subRouteId
     * @returns {Promise<void>}
     * @private
     */
    _fetchSubRouteStops = async subRouteId => {
        try {
            const mappedStops = await fetchSubrouteStops(subRouteId);
            await this.setState({ subRouteStops: mappedStops });
        } catch (err) {
            console.error("ERROR _fetchSubRouteStops: ", err);
            await this.setState({ subRouteStops: [] });
            return err;
        }
    };
    render() {
        const styles = StyleSheet.create({
            description: {
                letterSpacing: spacings.small
            },
            title: {
                letterSpacing: spacings.regular
            },
            container: {
                flexDirection: "column",
                paddingHorizontal: 15,
                flex: 1
            }
        });
        return (
            <View style={[styles.container]}>
                <AppText
                    color={colors.textAccent}
                    size={20}
                    style={[styles.title]}
                >
                    {this.props.name}
                    <small>{this.props.vehicle.vehicleId}</small>
                </AppText>
                <AppText
                    style={[styles.description]}
                    color={colors.textSecondaryAccent}
                >
                    Going to: {this.props.goingTo}
                </AppText>
                {
                    <TripDisplacementBar2
                        vehicle={this.props.vehicle}
                        progress={this.props.vehicle.routeProgress}
                        stops={this.state.subRouteStops}
                    />
                }
            </View>
        );
    }
}

InfoAndStops.propTypes = propTypes;

export default InfoAndStops;
