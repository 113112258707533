import React, {Component, Fragment} from 'react';
import {Marker, Popup} from 'react-mapbox-gl';
import PropTypes from 'prop-types';
import turfDistance from '@turf/distance';
import turfAlong from '@turf/along/index';
import turfBearing from '@turf/bearing/index';
import * as turf from '@turf/helpers/index';
import firebase from "../../lib/firebase";
import Bus11 from "../icons/Bus11";
import {parseISO} from 'date-fns';

export default class VehLayer extends Component {

    static propTypes = {
        map: PropTypes.shape({
            getCanvas: PropTypes.func,
        }),
    };

    //Determines speed of animation, approx 60 steps / sec
    static ANIMATION_STEPS = 200;

    constructor(props) {
        super(props);
        this.state = {
            vehicleData: {type: 'FeatureCollection', features: []},
            animationData: {},
            cronHandle: null,
            animationCounter: 0,
        };

        this.openPopup = this.openPopup.bind(this);
        this.onMouseEnter = this.onMouseEnter.bind(this);
        this.onMouseLeave = this.onMouseLeave.bind(this);
        this.getVehicles = this.getVehicles.bind(this);
        this.animate = this.animate.bind(this);
    }

    componentDidMount() {
        firebase
            .database()
            .ref(`/live/nearby`)
            .on("value", data => {
                this.getVehicles(data.val());
            });
    }

    render() {

        const vehFeatures = this.state.vehicleData.features;

        return (
            <Fragment>
                {vehFeatures && vehFeatures.length > 0 && vehFeatures.map((vehFeature, idx) => (
                    <Marker key={`veh-marker-${idx}`}
                            coordinates={vehFeature.geometry.coordinates}>
                        <div className="vehicle">
                            &nbsp;<Bus11 color="#efe05b" size={20}/>&nbsp;
                            {/*<span>  {vehFeature.properties.device_ts}</span>*/}
                        </div>
                    </Marker>
                ))}
            </Fragment>
        )
    }

    getVehicles(subroutes) {

        const routeCodes = process.env.REACT_APP_ROUTE_CODES.split(',');
        const vehFeatures = {};
        for (let [subrouteId, subroute] of Object.entries(subroutes)) {
            if(!routeCodes.includes(subroute.route_code)) {
                continue;
            }

            for(let veh of subroute.vehicles) {
                let prevVehFeature = vehFeatures[veh.coalesce_veh_trip_id];
                if(prevVehFeature && prevVehFeature.properties.freshness < veh.freshness) {
                    continue;
                }

                if(veh.freshness > 360) {
                    continue;
                }

                let vehTsStr = veh.device_ts || veh.db_ts;
                console.info(vehTsStr);
                let vehTs = parseISO(vehTsStr);
                if(Date.now() - vehTs > 360000) {
                    continue;
                }

                vehFeatures[veh.coalesce_veh_trip_id] = {
                    type: 'Feature',
                    id: veh.coalesce_veh_trip_id,
                    properties: {
                        bearing: veh.bearing,
                        route_color: subroute.route_color,
                        route_code: subroute.route_code,
                        coalesce_veh_trip_id: veh.coalesce_veh_trip_id,
                        vehicle_name: subroute.vehicle_name,
                        route_name: subroute.route_name,
                        subroute_name: subroute.subroute_name,
                        subroute_id: subrouteId,
                        freshness: veh.freshness,
                        device_ts: veh.device_ts,
                        db_ts: veh.db_ts,
                    },
                    geometry: {
                        type: 'Point',
                        coordinates: [veh.position_lon, veh.position_lat],
                    }
                }
            }
        }

        const vehJson = {
            type: 'FeatureCollection',
            features: Object.values(vehFeatures),
        };


        if(this.state.vehicleData.features.length === 0) {
            this.setState({
                vehicleData: vehJson
            });
            return;
        }

        const animationData = vehJson.features.reduce((result, nextVehPos) => {

            let prevVehPos = this.state.vehicleData.features.find(f => nextVehPos.id === f.id);
            if (!prevVehPos) {
                result[nextVehPos.id] = {
                    next: nextVehPos,
                    curr: nextVehPos,
                    trail: [],
                };
                return result;
            }

            const vehTrailEndpointCoords = [prevVehPos.geometry.coordinates, nextVehPos.geometry.coordinates];
            const lineDistance = turfDistance(...vehTrailEndpointCoords, {units: 'meters'});
            const segmentedTrail = [];

            for (let i = 0; i < lineDistance; i += lineDistance / VehLayer.ANIMATION_STEPS) {
                const segment = turfAlong({type: 'LineString', coordinates: vehTrailEndpointCoords}, i, {units: 'meters'});
                segmentedTrail.push(segment.geometry.coordinates);
            }

            result[nextVehPos.id] = {
                next: nextVehPos,
                curr: prevVehPos,
                trail: segmentedTrail,
            };

            return result;

        }, {});

        this.setState({
            animationCounter: 0,
            animationData: animationData,
        }, this.animate);

    }

    animate() {

        const nextFeatures = [];
        const counter = this.state.animationCounter;
        const animationData = this.state.animationData;

        for (const vehId in animationData) {
            if(!animationData.hasOwnProperty(vehId)) {
                continue;
            }

            const animationDatum = animationData[vehId];
            if(animationDatum.trail.length === 0) {
                nextFeatures.push(animationDatum.curr);
                continue;
            }

            if(counter >= VehLayer.ANIMATION_STEPS - 1) {
                nextFeatures.push(animationDatum.next);
                continue;
            }

            const currFeature = animationDatum.curr;

            // Update point geometry to a new position based on counter denoting
            // the index to access the arc.
            currFeature.geometry.coordinates = animationDatum.trail[counter];

            // Calculate the bearing to ensure the icon is rotated to match the route arc
            // The bearing is calculate between the current point and the next point, except
            // at the end of the arc use the previous point and the current point

            try {
                currFeature.properties.bearing = turfBearing(
                    turf.point(animationDatum.trail[counter]),
                    turf.point(animationDatum.trail[counter + 1])
                );
            } catch (err) {
                console.error(err);
            }
            nextFeatures.push(currFeature);
        }

        this.setState({
            vehicleData: {type:'FeatureCollection', features: nextFeatures},
            animationCounter: this.state.animationCounter + 1,
        });


        // Request the next frame of animation so long the end has not been reached.
        if (counter < VehLayer.ANIMATION_STEPS) {
            requestAnimationFrame(this.animate);
        }
    }

    openPopup(evt) {
        let fProps = evt.features[0].properties;
        this.setState({
            popup: <Popup coordinates={evt.lngLat} anchor="bottom">
                <button className="mapboxgl-popup-close-button" type="button" aria-label="Close popup"
                        onClick={() => this.setState({popup: null})}>×
                </button>
                {Object.keys(fProps).map((key) => {
                    return <div key={`props-${key}`}>{`${key}: ${fProps[key]}`}</div>
                })}
            </Popup>
        });
    }

    onMouseEnter() {
        this.props.map.getCanvas().style.cursor = 'pointer';
    }

    onMouseLeave() {
        this.props.map.getCanvas().style.cursor = '';
    }
}

