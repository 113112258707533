import React from "react";
import { View, StyleSheet } from "react-native";
import Climate from "../../components/Climate";
import { colors } from "../../lib/stylesheet";

export default () => {
    const styles = StyleSheet.create({
        container: {
            position: "absolute",
            top: 20,
            right: 20,
            backgroundColor: colors.backgroundPrimaryOpacity,
            borderRadius: 5
        }
    });
    return (
        <View style={[styles.container]}>
            <Climate />
        </View>
    );
};
