export default `
.station:before {
  content: ' ';
  display: inline-block;
  width: 12px;
  height: 12px;
  background: red;
  border-radius: 8px;
  margin: 0 8px;
}
.station {
  border-radius: 20px;
  padding-right: 12px;
  margin: -12px;
  line-height: 24px;
  font-size: 16px;
  white-space: nowrap;
    background: rgba(0,0,0,0.8);
  color: #fff;
}
]
.station span {
  display: none;
}
.station:hover {
  background: rgba(0,0,0,0.8);
  color: #fff;
  line-height: 26px;
  font-size: 18px;
}
.station:hover span {
  display: inline-block;
}
.vehicle {
  border-radius: 20px;
  background: rgba(0,0,0,0.8);
  color: #fff;
  font-size: 16px;  
  padding: 5px;
  padding-top: 8px;
  // line-height: 24px;
}
/*.vehicle:before {
  content: ' ';
  display: inline-block;
  width: 6px;
  height: 6px;
  margin: 0 4px;
}*/
`;
