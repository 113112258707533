import React, {Component} from "react";
import PropTypes from 'prop-types';
import ReactMapboxGl, {Layer} from "react-mapbox-gl";
import MARKER_STYLE from "../../styles/marker-style";
import VehLayer from '../../components/MapLayers/VehLayer';
import StopsLayer from "../../components/MapLayers/StopsLayer";
import RouteLayer from "../../components/MapLayers/RouteLayer";

const Map = ReactMapboxGl({
    accessToken:
        "pk.eyJ1IjoiZ3Vhd2EiLCJhIjoiY2pyOGszcHdsMDc0MjQ1cG5la2R1NW9wcyJ9.SeXLL2qTaX1ljEXZYgGYmA"
});

const mapStyle = {
    flex: 1
};

const paintLayer = {
    "fill-extrusion-color": "#aaa",
    "fill-extrusion-height": {
        type: "identity",
        property: "height"
    },
    "fill-extrusion-base": {
        type: "identity",
        property: "min_height"
    },
    "fill-extrusion-opacity": 0.6
};

export default class ThreeDMap extends Component {

    static propTypes = {
        onStyleLoad: PropTypes.func
    };


    constructor(props) {
        super(props);
        this.state = {
            zoom: [parseFloat(process.env.REACT_APP_MAP_ZOOM)],
            bearing: [parseFloat(process.env.REACT_APP_MAP_BEARING)],
            pitch: [parseFloat(process.env.REACT_APP_MAP_PITCH)],
            center: process.env.REACT_APP_MAP_CENTER.split(','),
            map: null
        };
    }

    onStyleLoad = map => {
        this.setState({
            map: map,
        });
        return this.props.onStyleLoad
            && this.props.onStyleLoad(map);
    };

    render() {
        return (
            <Map
                style={"mapbox://styles/mapbox/dark-v9"}
                containerStyle={mapStyle}
                onStyleLoad={this.onStyleLoad}
                zoom={this.state.zoom}
                center={this.state.center}
                pitch={this.state.pitch}
                bearing={this.state.bearing}
            >
                <StopsLayer/>
                <RouteLayer/>
                <Layer
                    id="3d-buildings"
                    sourceId="composite"
                    sourceLayer="building"
                    filter={["==", "extrude", "true"]}
                    type="fill-extrusion"
                    minZoom={14}
                    paint={paintLayer}
                />
                <style>{MARKER_STYLE}</style>
                <VehLayer map={this.state.map}/>
            </Map>
        );
    }


}
