import React from "react";
import { StyleSheet, Image, View } from "react-native-web";
import { colors } from "../../lib/stylesheet";
import AppText from "../../components/AppText";

export default () => {
    const styles = StyleSheet.create({
        text: {
            marginTop: 10
        },
        svgContainer: {
            position: "absolute",
            left: 0,
            right: 0,
            top: -75,
            alignItems: "center"
        },
        svg: {
            width: 150,
            height: 150,
            borderRadius: 5
        },
        leftBottomBackground: {
            backgroundColor: colors.primaryBackground,
            height: 125
        }
    });
    return (
        <View style={[styles.leftBottomBackground]}>
            <View style={[styles.svgContainer]}>
                <Image
                    source={{
                        uri: `${process.env.PUBLIC_URL}/images/qr-code.svg`
                    }}
                    style={[styles.svg]}
                />
                <AppText color={colors.textAccent} style={[styles.text]}>
                    Take this screen to your mobile
                </AppText>
            </View>
        </View>
    );
};
