/**
 * DEPRECATED use TripDisplacementBar2
 */
import React, { Component } from "react";
import _ from "lodash";
import { Text, StyleSheet, View } from "react-native";
import PropTypes from "prop-types";

const propTypes = {
    stops: PropTypes.array,
    progress: PropTypes.number,
    vehicle: PropTypes.object.isRequired
};

export default class TripDisplacementBar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            progress: this.props.progress
        };
    }

    componentWillReceiveProps(props) {
        const { progress } = this.props;
        if (props.progress !== progress) {
            this.setState({ progress: props.progress });
        }
    }

    /**
     * Returns red if stop percent is lower than progress percent
     * @param {Array} stopsBeforeMyLocation
     * @param {number} step
     * @returns {string}
     * @private
     */
    _colorCircle = (stopsBeforeMyLocation, step) => {
        const red = "#FF625D";
        const white = "#D8D8D8";
        if (
            _.get(stopsBeforeMyLocation[step], "stopPercentOnRoute") <=
            this.state.progress
        )
            return red;
        return white;
    };

    /**
     * Returns the percent of the stop based on how many other stops are in the array
     * @param {number} stopIndex
     * @param {number} displayStops
     * @returns {number}
     * @private
     */
    _getStopPercentOnRoute = (stopIndex, displayStops) => {
        // if first stop return 0%
        if (stopIndex === 0) {
            return 0;
        }
        // if route stops are only 2, return 100% for last stop
        if (displayStops === 2) {
            return 100;
        }
        // % * index
        return (100 / (displayStops - 1)) * stopIndex;
    };

    render() {
        let { progress } = this.state;
        // progress = progress > stops?stops:progress;
        /**
         * Get all the stops that are before the user's current closest stop
         * @type {Array}
         */
        let stopsBeforeMyLocation = _.takeWhile(
            this.props.stops,
            s =>
                s.properties.subroute_m <=
                this.props.vehicle.userSubRoutePosition
        );
        /**
         * If stops before user are more than 4 then group as a number the first
         * couple of stops and show a number instead of infinite stop circles
         */
        let groupedStops =
            stopsBeforeMyLocation.length > 5
                ? stopsBeforeMyLocation.length - 4
                : null;
        /**
         * Only show 4 stop circles in total
         * @type {Array}
         */
        stopsBeforeMyLocation = _.slice(stopsBeforeMyLocation, 0, 5);
        let displayStops = stopsBeforeMyLocation.length;
        /**
         * If the length of the stops is less than 2, means vehicle only has 1 stop before the user's
         * current closest stop so we add 2 so that we can put 2 circles at beginning and end of
         * visual line.
         */
        if (displayStops < 2) displayStops = 2;
        stopsBeforeMyLocation = stopsBeforeMyLocation.map((stop, i) => ({
            ...stop,
            /**
             * Adds the percent of the location of this stop based on all the other stops before
             * the user's closest stop to know if the vehicle has passed this route.
             */
            stopPercentOnRoute: this._getStopPercentOnRoute(i, displayStops)
        }));
        if (stopsBeforeMyLocation.length < 2) {
            stopsBeforeMyLocation.push({
                stopPercentOnRoute: this._getStopPercentOnRoute(1, displayStops)
            });
        }
        let step;
        let stop = [];

        for (step = 0; step < displayStops; step++) {
            stop.push(
                <View
                    key={step}
                    style={{
                        height: step === 0 && groupedStops ? 18 : 8,
                        width: step === 0 && groupedStops ? 18 : 8,
                        borderRadius: step === 0 && groupedStops ? 9 : 4,
                        backgroundColor: this._colorCircle(
                            stopsBeforeMyLocation,
                            step
                        ),
                        justifyContent: "center",
                        alignItems: "center"
                    }}
                >
                    {step === 0 && groupedStops && (
                        <Text style={[styles.circleText]}>{groupedStops}</Text>
                    )}
                </View>
            );
        }
        let displaced = `${progress <= 100 ? progress : 100}%`;
        // let displaced = `${Math.ceil(stopsPassed / stops * 100)}%`;
        //todo animate this
        let displacedLine = (
            <View style={[styles.displacementLine, { width: displaced }]} />
        );

        return (
            <View
                style={{
                    flex: 1,
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center"
                }}
            >
                <View style={styles.routeLine} />
                {displacedLine}
                {stop}
            </View>
        );
    }
}

const styles = StyleSheet.create({
    circleText: {
        color: "white",
        fontSize: 11
    },
    displacementLine: {
        height: 3,

        backgroundColor: "#FF625D",
        position: "absolute"
    },
    routeLine: {
        height: 3,
        width: "100%",
        backgroundColor: "#D8D8D8",
        position: "absolute"
    }
});
TripDisplacementBar.propTypes = propTypes;
TripDisplacementBar.defaultProps = {
    stops: []
};
