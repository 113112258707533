const colors = {
    primaryBackground: "#2B2B2B",
    accentBackground: "#3D3F41",
    secondaryAccentBackground: "#4C4E50",
    textPrimary: "white",
    textAccent: "rgba(255,255,255,.6)",
    textSecondaryAccent: "rgba(255,255,255,.4)",
    backgroundPrimaryOpacity: "rgba(0,0,0,.5)"
};

const spacings = {
    regular: 4,
    small: 1
};

export { colors, spacings };
