import React from "react";
import { array, number, object } from "prop-types";
import { StyleSheet, View } from "react-native";
import _ from "lodash";
import AppText from "../../components/AppText";
import { colors } from "../../lib/stylesheet";

const propTypes = {
    stops: array.isRequired,
    vehicle: object.isRequired
};

class TripDisplacementBar2 extends React.Component {
    state = {
        stopsBeforeMyLocation: [],
        stopsBeforeMyLocationLength: 0,
        stopsInBetween: 0,
        secondLineProgress: 0,
        singleRedLineProgress: 0
    };
    componentDidUpdate(prevProps) {
        if (prevProps.stops !== this.props.stops) {
            this._setBeginningStops();
        }
    }
    // componentDidMount() {
    //     this._setBeginningStops();
    // }

    _setBeginningStops = async () => {
        let stopsBeforeMyLocation = _.takeWhile(
            this.props.stops,
            s => s.properties.subroute_m <= this.props.vehicle.stopSubRouteM
        );
        let stopsBeforeMyLocationLength = stopsBeforeMyLocation.length;
        if (stopsBeforeMyLocationLength < 2) stopsBeforeMyLocationLength = 2;
        let stopsInBetween = stopsBeforeMyLocationLength;
        if (stopsBeforeMyLocationLength > 3) {
            let pendingStops = [];
            stopsBeforeMyLocation.map(s => {
                if (
                    s.properties.subroute_m >=
                    this.props.vehicle.vehicleSubRouteProgressMeters
                ) {
                    pendingStops.push(s);
                }
            });
            stopsInBetween = pendingStops.length - 1;
        } else {
            stopsInBetween = stopsBeforeMyLocationLength - 2;
        }
        await this.setState({
            stopsBeforeMyLocation,
            stopsBeforeMyLocationLength,
            stopsInBetween,
            firstLineProgress:
                (this.props.vehicle.vehicleSubRouteProgressMeters /
                    _.get(
                        stopsBeforeMyLocation[stopsBeforeMyLocation.length - 2],
                        "properties.subroute_m"
                    )) *
                100, // last stop before my stop m / vehicleSubRouteProgressMeters * 100
            secondLineProgress:
                ((this.props.vehicle.vehicleSubRouteProgressMeters -
                    _.get(
                        stopsBeforeMyLocation[stopsBeforeMyLocation.length - 2],
                        "properties.subroute_m"
                    )) /
                    _.get(
                        stopsBeforeMyLocation[stopsBeforeMyLocation.length - 1],
                        "properties.subroute_m"
                    )) *
                100,
            singleRedLineProgress: this.props.vehicle.routeProgress
        });
    };

    _renderBar = styles => {
        if (this.state.stopsBeforeMyLocationLength === 2) {
            return (
                <View style={[styles.container]}>
                    <View style={[styles.singleWhiteLineContainer]}>
                        <View style={[styles.singleWhiteLine]}>
                            <View style={[styles.singleRedLine]} />
                        </View>
                        <View style={[styles.circle, styles.firstCircle]} />
                        <View style={[styles.circle]} />
                    </View>
                </View>
            );
        }
        return (
            <View style={[styles.container]}>
                <View style={[styles.whiteLineContainer1]}>
                    <View style={[styles.whiteLine1]}>
                        <View style={[styles.firstRedLine]} />
                    </View>
                    <View style={[styles.circle, styles.firstCircle]} />
                    <View
                        style={[
                            styles.circle,
                            this.state.stopsInBetween > 1 &&
                                styles.secondCircle,
                            this.state.secondLineProgress > 0 &&
                                styles.redCircle
                        ]}
                    >
                        <AppText
                            weight={"bolder"}
                            color={colors.primaryBackground}
                            size={11}
                            style={[styles.text]}
                        >
                            {this.state.stopsInBetween > 1 &&
                                this.state.stopsInBetween}
                        </AppText>
                    </View>
                </View>
                <View style={[styles.whiteLineContainer2]}>
                    <View style={[styles.whiteLine2]}>
                        <View style={[styles.secondRedLine]} />
                    </View>
                    <View style={[styles.circle]} />
                </View>
            </View>
        );
    };

    render() {
        const white = "#D8D8D8";
        const red = "#FF625D";
        const styles = StyleSheet.create({
            singleRedLine: {
                position: "absolute",
                left: 0,
                top: 0,
                height: 3,
                backgroundColor: red,
                width: `${this.state.singleRedLineProgress}%`,
                maxWidth: "100%",
                borderTopLeftRadius: 100,
                borderBottomLeftRadius: 100
            },
            singleWhiteLine: {
                backgroundColor: white,
                height: 3,
                position: "absolute",
                width: "100%",
                borderTopLeftRadius: 100,
                borderBottomLeftRadius: 100
            },
            singleWhiteLineContainer: {
                width: "100%",
                justifyContent: "space-between",
                flexDirection: "row",
                alignItems: "center",
                borderTopLeftRadius: 4,
                borderBottomLeftRadius: 4
            },
            text: {
                letterSpacing: 0
            },
            secondCircle: {
                width: 16,
                height: 16,
                borderRadius: 8
            },
            redCircle: {
                backgroundColor: red
            },
            firstCircle: {
                backgroundColor: red
            },
            circle: {
                width: 8,
                height: 8,
                borderRadius: 4,
                backgroundColor: white,
                justifyContent: "center",
                alignItems: "center"
            },
            whiteLineContainer2: {
                width: "70%",
                justifyContent: "flex-end",
                flexDirection: "row",
                alignItems: "center",
                borderTopRightRadius: 14,
                borderBottomRightRadius: 14
            },
            whiteLineContainer1: {
                width: "30%",
                justifyContent: "space-between",
                flexDirection: "row",
                alignItems: "center",
                borderTopLeftRadius: 4,
                borderBottomLeftRadius: 4
            },
            firstRedLine: {
                position: "absolute",
                left: 0,
                top: 0,
                height: 3,
                backgroundColor: red,
                width: `${this.state.firstLineProgress}%`,
                maxWidth: "100%",
                borderTopLeftRadius: 100,
                borderBottomLeftRadius: 100
            },
            secondRedLine: {
                position: "absolute",
                left: 0,
                top: 0,
                height: 3,
                backgroundColor: red,
                width: `${this.state.secondLineProgress}%`,
                maxWidth: "100%",
                borderTopRightRadius: 100,
                borderBottomRightRadius: 100
            },
            container: {
                flex: 1,
                flexDirection: "row"
            },
            whiteLine1: {
                backgroundColor: white,
                height: 3,
                position: "absolute",
                width: "100%",
                borderTopLeftRadius: 100,
                borderBottomLeftRadius: 100
            },
            whiteLine2: {
                backgroundColor: white,
                height: 3,
                position: "absolute",
                width: "100%",
                borderTopRightRadius: 100,
                borderBottomRightRadius: 100
            }
        });
        return this._renderBar(styles);
    }
}

TripDisplacementBar2.propTypes = propTypes;

export default TripDisplacementBar2;
