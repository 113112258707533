import React from "react";
import { View, Image, StyleSheet } from "react-native";
import { object } from "prop-types";
import InfoAndStops from "./InfoAndStops";
import TimeReference from "./TimeReference";
import firebase from "../../lib/firebase";
import { fetchSingleVehicle } from "../../service";
import { vehicle_route_map } from "../../utils";
// import styles from "./routeInfo.module.css";

const propTypes = {
    route: object.isRequired
};

class RouteInfo extends React.Component {
    state = {
        route: this.props.route
    };
    componentDidMount() {
        if(this.props.route && this.props.route.vehicleId) {
            firebase
                .database()
                .ref(`/live/nearby/${this.props.route.vehicleId}`)
                .on("value", async snap => {
                    this._fetchSingleVehicle(this.props.route.vehicleId);
                });
        }
    }

    _fetchSingleVehicle = async vehicleId => {
        try {
            const json = await fetchSingleVehicle(vehicleId);
            this.setState({ route: vehicle_route_map(json) });
        } catch (err) {
            console.error("ERROR _fetchSingleVehicle: ", err);
            return err;
        }
    };

    render() {
        return (
            <View style={styles.container}>
                <Image
                    style={styles.circleImage}
                    source={{ uri: this.state.route.image }}
                />
                <InfoAndStops
                    vehicle={this.state.route}
                    name={this.state.route.title}
                    goingTo={this.state.route.description || ''}
                />
                <TimeReference time={this.state.route.eta} />
            </View>
        );
    }
}

const styles = StyleSheet.create({
    container: {
        width: "100%",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        marginTop: 10
    },
    circleImage: {
        width: 60,
        height: 60,
        borderRadius: 30
    }
});

RouteInfo.propTypes = propTypes;

export default RouteInfo;
