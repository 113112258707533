import React from "react";
import { Image, View, StyleSheet } from "react-native";
import AppText from "../components/AppText";
import { colors } from "../lib/stylesheet";

export default () => {
    const styles = StyleSheet.create({
        logo: {
            width: 70,
            height: 70,
            borderWidth: 2,
            borderRadius: 35,
            borderColor: "white"
        },
        container: {
            backgroundColor: colors.primaryBackground,
            justifyContent: "space-around",
            alignItems: "center",
            height: 100,
            paddingHorizontal: 15,
            flexDirection: "row"
        },
        text: {
            letterSpacing: 3
        }
    });
    return (
        <View style={[styles.container]}>
            <Image
                source={{
                    uri: `${process.env.PUBLIC_URL}/images/logo-guawa.png`
                }}
                style={[styles.logo]}
            />
            <AppText
                weight={"bold"}
                size={40}
                color={colors.textPrimary}
                style={[styles.text]}
            >
                GUAWA TV
            </AppText>
        </View>
    );
};
