import { createStore } from "redux";

function geo(state = {}, action) {
    switch (action.type) {
        case "SET_GEO":
            return { ...action.payload };
        default:
            return state;
    }
}

const store = createStore(geo);

export default store;
