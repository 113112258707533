import React, {Component} from "react";
import { StyleSheet, View } from "react-native";
import AppText from "../AppText";
import {format} from 'date-fns';


export default class ClimateBox extends  Component {

    constructor(props) {
        super(props);
        this.state = {
            cronHandle: null,
            now: new Date(),
        };
    }

    componentDidMount() {
        this.setState({
            cronHandle: window.setInterval(this.updateNow, 5000)
        });
    }


    updateNow = () => {
        this.setState({
            now: new Date(),
        });
    };



    render() {
        return (
            <View style={[styles.container]}>
                <View>
                    <AppText size={50} weight={"500"} color={"white"}>
                        {format(this.state.now, 'hh:mm')}
                        <AppText color={"white"} size={20}>
                            {format(this.state.now, 'a')}
                        </AppText>
                    </AppText>
                    <View style={[styles.column]}>
                        <AppText size={70} weight={"500"} color={"white"}>
                            {format(this.state.now, 'dd')}
                        </AppText>
                        <View style={[styles.centered]}>
                            <AppText size={25} weight={"500"} color={"white"}>
                                {format(this.state.now, 'MMM').toUpperCase()}
                            </AppText>
                            <AppText size={25} weight={"500"} color={"white"}>
                                {format(this.state.now, 'yyyy')}
                            </AppText>
                        </View>
                    </View>
                </View>
            </View>
        );
    }
};

const styles = StyleSheet.create({
    centered: {
        justifyContent: "center",
        alignItems: "center"
    },
    column: {
        flexDirection: "row",
        justifyContent: "space-between"
    },
    container: {
        padding: 15,
        justifyContent: "center",
        alignItems: "center",
        flex: 1
    }
});
