import React, {Component} from 'react';
import {GeoJSONLayer} from 'react-mapbox-gl';


export default class StopsLayer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            routeGeoJson: {
                type: 'FeatureCollection',
                features: [],
            }
        }
    }

    async componentDidMount() {
        let url = `${process.env.REACT_APP_SERVER_URL}/rest/route/linksByRouteCodes/${process.env.REACT_APP_ROUTE_CODES}`;
        const response = await fetch(url);
        const routeGeoJson = await response.json();
        this.setState({
            routeGeoJson: routeGeoJson
        })
    }

    render() {
        return (
            <GeoJSONLayer
                linePaint={{"line-color": "white", "line-width": 5}}
                lineLayout={{"line-join": "round", "line-cap": "round"}}
                data={this.state.routeGeoJson}
                symbolLayout={{
                    "text-field": "{place}",
                    "text-font": [
                        "Open Sans Semibold",
                        "Arial Unicode MS Bold"
                    ],
                    "text-offset": [0, 0.6],
                    "text-anchor": "top"
                }}
            />
        )
    }
}

