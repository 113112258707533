const vehicle_route_map = route => {
    if (!route) return { eta: 0 };
    return {
        routeId: route.route_id,
        subRouteId: route.subroute_id,
        vehicleId: route.vehicle_id,
        title: route.route_name,
        description: route.subroute_direction,
        routeColor: route.route_color,
        routeCode: route.route_code,
        position_lat: route.position_lat,
        position_lon: route.position_lon,
        userSubRoutePosition: route.user_subroute_position,
        vehicleSubRouteProgressMeters:
            route.vehicle_subroute_progress_meters || 0,
        routeProgress: Math.ceil(
            ((route.vehicle_subroute_progress_meters || 0) /
                route.user_subroute_position) *
                100
        ),
        routeUserPosition: Math.ceil(
            (route.user_subroute_position / route.subroute_length_meters) * 100
        ),
        eta: Math.ceil(route.eta / 60),
        image: vehicleImage(route.route_operator),
        stopSubRouteM: route.stop_subroute_m
    };
};

const vehicleImage = route_operator => {
    switch (route_operator) {
        case "AMA":
            return "https://firebasestorage.googleapis.com/v0/b/guawa-cd62b.appspot.com/o/providers%2Fati-small.png?alt=media&token=5bbc7f6b-b134-4118-9fdb-c88c21d473b2";
        case "First Transit":
            return "https://firebasestorage.googleapis.com/v0/b/guawa-cd62b.appspot.com/o/providers%2FFirst-Transit-logo.png?alt=media&token=57ea74ab-cab1-42c7-a838-1526bbc2a1ea";
        case "Universidad Sagrado Corazón":
            return "https://firebasestorage.googleapis.com/v0/b/guawa-cd62b.appspot.com/o/providers%2Fdelfin.png?alt=media&token=dee8319e-8d6c-464c-8231-e95259a7683b";
        case "Codevisa":
            return "https://firebasestorage.googleapis.com/v0/b/guawa-cd62b.appspot.com/o/providers%2Ftrolley.png?alt=media&token=085de2cc-87ce-438d-a732-bb7313b3ce65";
        case "ACI Herzog":
            return "https://firebasestorage.googleapis.com/v0/b/guawa-cd62b.appspot.com/o/providers%2Ftuapp.png?alt=media&token=de94b648-1976-427c-81d8-c72a63f617b5";
        case "Plaza Las Américas":
            return "https://firebasestorage.googleapis.com/v0/b/guawa-cd62b.appspot.com/o/providers%2Fplaza.png?alt=media&token=597f1aff-4087-4dae-9a34-129016368e26";
        case "ATM":
            return "https://firebasestorage.googleapis.com/v0/b/guawa-cd62b.appspot.com/o/providers%2FATM.png?alt=media&token=2c25609a-4840-4a6b-9a91-f05df196d675";
        default:
            return "https://firebasestorage.googleapis.com/v0/b/guawa-cd62b.appspot.com/o/providers%2Fpublico.png?alt=media&token=115e92bc-fade-40c0-8aa0-5ad05b00fe38";
    }
};

export { vehicle_route_map };
