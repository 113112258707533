import React from "react";
import { array, bool } from "prop-types";
import {
    FlatList,
    ScrollView,
    Image,
    StyleSheet,
    View,
    ActivityIndicator,
    Text
} from "react-native";
import RouteInfo from "./RouteInfo";
import { colors } from "../lib/stylesheet";

const propTypes = {
    vehicles: array.isRequired,
    loading: bool
};

function Vehicles(props) {
    const styles = StyleSheet.create({
        borderCover: {
            width: "100%",
            height: "100%",
            position: "absolute",
            borderWidth: 1,
            borderColor: "white",
            top: 0,
            left: 0
        },
        videoContainer: {
            width: "100%",
            justifyContent: "center",
            alignItems: "center"
        },
        videoText: {
            fontSize: 30,
            fontWeight: "bold",
            color: "rgba(0,0,0,.8)"
        },
        container: {
            paddingTop: 15,
            paddingHorizontal: 15,
            backgroundColor: colors.accentBackground
        }
    });
    function _renderRouteList() {
        if (props.loading) return <ActivityIndicator color={"#ff4144"} />;
        if (props.vehicles.length === 0)
            return (
                <View style={[styles.videoContainer]}>
                    <Image
                        source={{
                            uri: `${
                                process.env.PUBLIC_URL
                            }/images/running-guy2.gif`
                        }}
                        style={{ width: 250, height: 220 }}
                    />
                    <Text style={[styles.videoText]}>Nothing Around</Text>
                </View>
            );
        return (
            <FlatList
                data={props.vehicles}
                renderItem={({ item }) => <RouteInfo route={item} />}
            />
        );
    }
    return (
        <ScrollView style={[styles.container]}>{_renderRouteList()}</ScrollView>
    );
}

Vehicles.propTypes = propTypes;

export default Vehicles;
